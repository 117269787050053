// src/App.js
import React, {useState} from 'react';
import Header from './Header.js';
import Main from './Main.js';
import Footer from './Footer.js';
import './App.css';

const App = () => {
    const [activeSection, setActiveSection] = useState('home');

    const handleMenuClick = (section: React.SetStateAction<string>) => {
        setActiveSection(section);
    };
    return (
        <div className="app-container">
            <Header onMenuClick={handleMenuClick}/>
            <Main activeSection={activeSection}/>
            <Footer />
        </div>
    );
};

export default App;
