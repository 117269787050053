// src/components/Footer.js
import React from 'react';
import './App.css'; // Import the CSS for styling

const Footer = () => {
    return (
        <footer className="footer">
            <p>Footer Content Here</p>
        </footer>
    );
};

export default Footer;
