// src/components/Main.js
import React from 'react';
import './App.css'; // Import the CSS for styling

const Main = ({activeSection}) => {
    return (
        <main className="main-content animated-background">
            {/*<h2>Main Content Area</h2>*/}
            {/*<p>This is where your main content goes.</p>*/}
            <section id="home" style={{display: activeSection === 'home' ? 'flex' : 'none'}}>
                {/*<h1>Find Your Dream Investment Today</h1>*/}
                {/*<h2>Browse for listings, connect with trusted agents, and make your next move with confidence</h2>*/}
                <div>
                    <h1>Find Your Dream Investment Today</h1>
                    <h2>Browse for listings, connect with trusted agents, and make your next move with confidence</h2>
                    <div className="section-content">
                        <div className="column">
                            <p>Welcome to Midas Global Investments, where we make buying, selling, and
                                renting properties easy.
                                Whether you’re searching for a new commercial, mixed use, multi-family or single family
                                property,
                                or looking to sell your property, our dedicated team is here to help you every step of
                                the
                                way.</p>
                        </div>
                        <div className="column">
                            <p>
                                Discover a variety of properties across different neighborhoods.
                                Each listing provides detailed information, high-quality images, and a virtual tour
                                option to help
                                you get a sense of the property before visiting in person.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about" style={{display: activeSection === 'about' ? 'flex' : 'none'}}>
                <div>
                    <h3>At Midas, we believe that finding a place to call home should be a seamless
                        and enjoyable experience. Established in 2021, our team of experienced real
                        estate professionals has been committed to helping clients buy, sell,
                        and rent properties across Dallas.</h3>
                    <div className="section-content">
                        <div className="column">
                            <p>
                                To connect people with their ideal properties through outstanding service, expertise, and integrity.
                            </p>
                        </div>
                        <div className="column">
                            <h3> Meet Our Team:</h3>
                            <p>Sudheer Babu Bagham - 909-225-5636   </p>
                            <p>Raj - 909-225-5636   </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="properties" style={{display: activeSection === 'properties' ? 'flex' : 'none'}}>
                <h2>Properties Section</h2>
                <p>Get in touch through the Properties section.</p>
            </section>
            <section id="contact" style={{display: activeSection === 'contact' ? 'flex' : 'none'}}>
                <h2>Contact Section</h2>
                <p>Get in touch through the Contact section.</p>
            </section>

        </main>
    );
};

export default Main;
