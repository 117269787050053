// // src/components/Header.js
// import React from 'react';
// import './App.css'; // Import the CSS for styling
//
// const Header = () => {
//     return (
//         <header className="header">
//             <h1>Welcome to My Page</h1>
//         </header>
//     );
// };
//
// export default Header;

// Header.js
import React, {useState} from 'react';
import './App.css';
import './Header.css';

// @ts-ignore
const Header = ({onMenuClick} ) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const handleItemClick = (event, section) => {
        event.preventDefault();
        onMenuClick(section);
        setIsMenuOpen(false); // Close the menu after selecting an item
    };
    return (
        <header className="header">
            <div className="logo">
                <h1>Midas</h1>
            </div>

            <nav className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
                <div className="menu-toggle" onClick={toggleMenu}>
                    ☰
                </div>
                <ul>
                    <li><a href={"#home"} onClick={(event) => handleItemClick(event,'home')}>Home</a></li>
                    <li><a href="#about" onClick={(event) => handleItemClick(event,'about')}>About Us</a></li>
                    <li><a href="#properties" onClick={(event) => handleItemClick(event,'properties')}>Properties</a></li>
                    <li><a href="#contact" onClick={(event) => handleItemClick(event,'contact')}>Contact Us</a></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
